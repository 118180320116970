import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'

const Hero = () => {
  return (
    <Flex h="535px" w="full" position="relative" background="#0000003b">
      <NextImage
        src={s3ImageURL('hero.jpeg')}
        alt={'hero'}
        fill
        style={{ objectFit: 'cover', zIndex: '-2' }}
        priority
        sizes="100vw"
      />
      <Flex
        direction="column"
        px={10}
        h="full"
        w="full"
        justifyContent="center"
        alignItems={{ base: 'center', md: 'flex-start' }}
        rowGap={6}
      >
        <Heading size="xl" color="white">
          Mousline et MiiMOSA s&apos;engage
        </Heading>
        <Text size="2xl" color="white" whiteSpace="pre-wrap">
          {
            'dans le soutien de la filière pomme de terre, en faveur de la\n transition agricole et alimentaire auprès de ses agriculteurs.'
          }
        </Text>
        <Link href="#form">
          <Button bg="yellow" width="fit-content">
            <Text color="black" size="xl">
              Je candidate
            </Text>
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}

export default Hero
